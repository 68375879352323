import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WOW from "wowjs";

import { FiSend } from "react-icons/fi";
import { TiFlowMerge } from "react-icons/ti";
import { BiAlarmOff } from "react-icons/bi";
import { GiFlexibleStar, GiPayMoney } from "react-icons/gi";
import Button from "react-bootstrap/Button";

import { useLocation, Link } from "react-router-dom";

import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";

import protectedImg from "./image/protected.svg";
import drink from "./image/drink.mp4";

import "./Index.css";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgClass: "hero-index1",
      currentNo: 1,
    };
  }

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
    this.interval = setInterval(() => {
      if (this.state.currentNo === 1) {
        this.setState({ bgClass: "hero-index2", currentNo: 2 });
      } else if (this.state.currentNo === 2) {
        this.setState({ bgClass: "hero-index3", currentNo: 3 });
      } else if (this.state.currentNo === 3) {
        this.setState({ bgClass: "hero-index4", currentNo: 4 });
      } else {
        this.setState({ bgClass: "hero-index1", currentNo: 1 });
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    var className = "hero-global " + this.state.bgClass;
    return (
      <div>
        <div className={className}>
          <NavBar />
          <div className="container">
            <Row>
              <Col sm>
                <div className="header-text">
                  <h3 className="wow fadeInUp">
                    "Get your
                    <br />
                    money working!"
                  </h3>
                  <p className="wow fadeInUp">
                    {/* With impressive interest rates, tools & guides,
                    MetrocommOnline is the smartest way to plan, send money, save
                    & invest.
                    <br />
                    <br /> */}
                    MetrocommOnline helps you achieve financial freedom by
                    enabling you save responsibly and invest on the go with
                    impressive interest rates.
                  </p>
                </div>
              </Col>
              <Col sm></Col>
            </Row>
          </div>
        </div>
        <div className="world-class" style={{ paddingTop: 100 }}>
          <div className="container text-center wow fadeInUp">
            <FiSend className="icon" />
            <h3>World Class. Worldwide.</h3>
            <p>Send money instantly from anywhere to anywhere</p>
          </div>
          <div className="map-bg"></div>
        </div>
        <div className="world-class" style={{ paddingTop: 100 }}>
          <div className="container text-center wow fadeInUp">
            <h3>
              A more versatile home for your
              <br /> financial life
            </h3>
            <p>
              We help you access financial services you can’t get through your
              bank
            </p>
          </div>
          <div className="card-container" style={{ paddingTop: 50 }}>
            <Row>
              <Col sm>
                <div className="card-item wow fadeInUp">
                  <TiFlowMerge className="card-icon" />
                  <h4>Cheap Transactions</h4>
                  <p>
                    Access one of the most cost effective transactions globally.
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="card-item wow fadeInUp">
                  <BiAlarmOff className="card-icon" />
                  <h4>Instant remittances</h4>
                  <p>
                    Send money to your friends and family worldwide - instantly,
                    free.
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="card-item wow fadeInUp">
                  <GiFlexibleStar className="card-icon" />
                  <h4>Flexible payments</h4>
                  <p>
                    Get paid by your employer in any currency, or combination of
                    currencies you want.
                  </p>
                </div>
              </Col>
              <Col sm>
                <div className="card-item wow fadeInUp">
                  <GiPayMoney className="card-icon" />
                  <h4>Invest Money</h4>
                  <p>
                    We make investment simpler than it sounds. Invest in mutual
                    funds tailored to you.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="trusted" style={{ marginTop: 200 }}>
          <div className="container">
            <Row>
              <Col sm></Col>
              <Col sm>
                <div className="wow fadeInUp">
                  <h3>Trusted & Transparent</h3>
                  <p>
                    MetrocommOnline is fully reserved. Unlike other banks, we
                    don’t loan out your money. We publish our holdings and
                    obligations in real time.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="protected">
          <div className="container">
            <Row>
              <Col sm>
                <div>
                  <h4 className="wow fadeInUp">Your money is well protected</h4>
                  <br />
                  <br />
                  <ul>
                    <li className="wow fadeInUp">
                      <div className="protected-list">
                        <h3>Bank-grade Security</h3>
                        <p>
                          Our payment processors are PCIDSS compliant to ensure
                          optimum security of your data electronically.
                        </p>
                      </div>
                    </li>
                    <li className="wow fadeInUp">
                      <div className="protected-list">
                        <h3>Best-In-Class Investments</h3>
                        <p>
                          We invest in low risk fixed income securities which
                          include treasury bills, government bonds, and
                          professionally managed mutual funds.
                        </p>
                      </div>
                    </li>
                    <li className="wow fadeInUp">
                      <div className="protected-list">
                        <h3>Asset Under Secure Watch</h3>
                        <p>
                          We invest your savings in low risk financial
                          instruments held on behalf of our customers by
                          Meristem Trustees, registered with the Securities and
                          Exchange Commission (SEC).
                        </p>
                      </div>
                    </li>
                  </ul>
                  <br />
                  <br />
                </div>
              </Col>

              <Col sm>
                <img
                  className="wow fadeInUp"
                  src={protectedImg}
                  alt="protected"
                />
              </Col>
            </Row>
          </div>
        </div>
        <div class="juice" style={{ paddingTop: 100 }}>
          <div className="container">
            <Row>
              <Col sm>
                <video src={drink} loop={true} muted={true} autoPlay={true} />
              </Col>
              <Col sm>
                <div className="never wow fadeInUp">
                  <h3>Never out of juice</h3>
                  <p>
                    Saving and investing money with MetrocommOnline means your
                    money works hard all day, everyday for you.
                  </p>
                  <Button
                    as={Link}
                    to={{ pathname: "user " }}
                    target="_blank"
                    variant="success"
                    style={{ fontWeight: "bold" }}
                  >
                    START TODAY
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Index;
