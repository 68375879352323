import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WOW from "wowjs";

import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";

import "./About.css";

class About extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <div>
        <NavBar />
        <div className="hero-about about-bg">
          <div className="container">
            <Row>
              <Col sm>
                <div className="header-text">
                  <h3 className="wow fadeInUp">
                    MetrocommOnline is changing the way people access and invest
                    money.
                  </h3>
                </div>
              </Col>
              <Col sm></Col>
            </Row>
          </div>
        </div>
        <div className="about-write container" style={{ paddingTop: 150 }}>
          <Row>
            <Col sm>
              <p className="wow fadeInUp">
                MetrocommOnline serves 184+ countries, across 30+ currencies
                (traditional and crypto) and commodities with frictionless
                foreign exchange and cross-border remittance for members around
                the world. Since our launch in 2015, MetrocommOnline has powered
                more than US$4+ billion in transactions.
                <br />
                <br />
                MetrocommOnline combines a platform app model with payment
                connectivity to offer financial services to a global market.
                MetrocommOnline empowers innovation in financial services
                through a platform approach where app developers and fintech
                partners can leverage MetrocommOnline reach through licensed
                relationships with banks and financial services partners around
                the world. Built on a core of proprietary technologies and
                e-money apps, MetrocommOnline embraces a future where people and
                businesses around the world have access to safe, transparent,
                fair and affordable financial services.
              </p>
            </Col>
          </Row>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
