// import React, { useEffect, useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
// import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import { useLocation, Link } from "react-router-dom";

import logoSvg from "./logo.svg";
import logoPng from "./logo.png";

import "./NavBar.css";

import React, { Component } from "react";
import { Navbar as NewNav } from "react-responsive-navbar-overlay";
import { BrowserRouter, Route } from "react-router-dom";

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navBackground: "",
      mobile: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.addEventListener("scroll", () => {
      const backgroundcolor = window.scrollY < 100 ? "" : "rgb(32, 41, 54)";

      this.setState({ navBackground: backgroundcolor });
    });
  }

  render() {
    return !this.state.mobile ? (
      <div>
        <Navbar
          collapseOnSelect
          expand="lg"
          className="navbar-header"
          fixed="top"
          style={{ backgroundColor: this.state.navBackground }}
        >
          <div className="container">
            <Navbar.Brand
              className="brandlogo"
              as={Link}
              to="/"
              id="brand-text"
              style={{ fontWeight: 700 }}
            >
              {/* <img alt="" src={logoPng} width="40" height="50" className="" /> */}
              &nbsp;&nbsp;MCB
            </Navbar.Brand>
            <Navbar.Toggle
              aria-controls="responsive-navbar-nav"
              style={{ backgroundColor: "white" }}
            />
            {/* <Navbar.Toggle data-toggle="collapse" data-target="#menu" /> */}
            {/* <button
              className="btn btn-primary navbar-btn pull-right"
              data-toggle="collapse"
              data-target="#menu"
            >
              <span class="fa fa-bars"></span> Menu
            </button> */}

            <Navbar.Collapse id="responsive-navbar-nav menu">
              <Nav className="mr-auto nav-text">
                <Nav.Link as={Link} to="/about" id="nav-text">
                  About
                </Nav.Link>
                <Nav.Link
                  as={Link}
                  to={{
                    pathname: "user/contact.php ",
                  }}
                  target="_blank"
                  id="nav-text"
                >
                  Contact Us
                </Nav.Link>
                {/* <Nav.Link as={Link} to="/" id="nav-text">
                  Save
                </Nav.Link>
                <Nav.Link as={Link} to="/" id="nav-text">
                  Invest
                </Nav.Link> */}
              </Nav>
              <Form inline>
                <Button
                  as={Link}
                  to={{ pathname: "user " }}
                  target="_blank"
                  variant="success"
                  style={{ fontWeight: "bold" }}
                >
                  LOGIN
                </Button>
                &nbsp;&nbsp;
                <Button
                  as={Link}
                  to={{
                    pathname: "user/create.php ",
                  }}
                  target="_blank"
                  variant="success"
                  style={{ fontWeight: "bold" }}
                >
                  CREATE ACCOUNT
                </Button>
              </Form>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </div>
    ) : (
      <div>
        <BrowserRouter>
          <NewNav
            className="navbar-header"
            fixed="top"
            style={{ backgroundColor: this.state.navBackground }}
          />
          /* These are the default routes, if you change the properties change
          the routes too. */
          {/* <Route exact path="/" component={Home} />
          <Route path="/portfolio" component={Portfolio} />
          <Route path="/blog" component={Blog} /> */}
        </BrowserRouter>
      </div>
    );
  }
}

export default NavBar;

// export default function NavBar() {
//   const { pathname } = useLocation();

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, [pathname]);

//   window.addEventListener("scroll", () => {
//     if (window.scrollY > 20) {
//       document.querySelector(".navbar-header").className = "navbar-header scro";
//     } else {
//       document.querySelector(".navbar-header").className = "navbar-header";
//     }
//   });

//   return (
//     <div>
//       <Navbar
//         collapseOnSelect
//         expand="lg"
//         className="navbar-header"
//         fixed="top"
//       >
//         <div className="container">
//           <Navbar.Brand
//             className="brandlogo"
//             as={Link}
//             to="/"
//             id="brand-text"
//             style={{ fontWeight: 700 }}
//           >
//             <img alt="" src={logoPng} width="40" height="50" className="" />
//             &nbsp;&nbsp;upholdbank
//           </Navbar.Brand>
//           <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//           <Navbar.Collapse id="responsive-navbar-nav">
//             <Nav className="mr-auto nav-text">
//               <Nav.Link as={Link} to="/about" id="nav-text">
//                 About
//               </Nav.Link>
//               <Nav.Link as={Link} to="/" id="nav-text">
//                 Plan
//               </Nav.Link>
//               <Nav.Link as={Link} to="/" id="nav-text">
//                 Save
//               </Nav.Link>
//               <Nav.Link as={Link} to="/" id="nav-text">
//                 Invest
//               </Nav.Link>
//             </Nav>
//             <Form inline>
//               <Button
//                 as={Link}
//                 to={{ pathname: "user " }}
//                 target="_blank"
//                 variant="outline-success"
//                 style={{ fontWeight: "bold" }}
//               >
//                 LOGIN
//               </Button>
//               &nbsp;&nbsp;
//               <Button
//                 as={Link}
//                 to={{
//                   pathname:
//                     "https://play.google.com/store/apps/details?id=com.uphold.wallet&hl=en&gl=US ",
//                 }}
//                 target="_blank"
//                 variant="success"
//                 style={{ fontWeight: "bold" }}
//               >
//                 CREATE ACCOUNT
//               </Button>
//             </Form>
//           </Navbar.Collapse>
//         </div>
//       </Navbar>
//     </div>
//   );
// }
