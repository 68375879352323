import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useLocation, Link } from "react-router-dom";

import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="container">
          <Row>
            <Col sm>
              <div className="footer-col">
                <h3>Company</h3>
                <p>
                  <Link className="footer-link" to="/about">
                    About Us
                  </Link>
                </p>
                <p>
                  <Link
                    className="footer-link"
                    to={{
                      pathname: "user/contact.php ",
                    }}
                    target="_blank"
                  >
                    Contact Us
                  </Link>
                </p>
              </div>
            </Col>
            <Col sm>
              <div className="footer-col">
                <h3>Products</h3>
                <p>Saving Plans</p>
                <p>Investment Plans</p>
              </div>
            </Col>
            <Col sm>
              <div className="footer-col">
                <h3>Contact</h3>

                <a href="mailto:hello@metrocommonline.com">
                  <p>hello@metrocommonline.com</p>
                </a>
                <a href="tel:+442030025693">
                  <p>Fax: +442030025693</p>
                </a>
              </div>
            </Col>
            {/* <Col sm>
              <div className="footer-col">
                <h3>Legal</h3>
                <Link
                  className="footer-link"
                  to={{ pathname: "https://uphold.com/en/legal " }}
                  target="_blank"
                >
                  <p>Legal</p>
                </Link>
                <Link
                  className="footer-link"
                  to={{
                    pathname:
                      "https://uphold.com/en/legal/membership-agreement/general ",
                  }}
                  target="_blank"
                >
                  <p>Agreements</p>
                </Link>

                <Link
                  className="footer-link"
                  to={{
                    pathname: "https://uphold.com/en/legal/privacy-policy ",
                  }}
                  target="_blank"
                >
                  <p>Privacy & Data Policy</p>
                </Link>

                <Link
                  className="footer-link"
                  to={{
                    pathname: "https://uphold.com/en/legal/california-privacy ",
                  }}
                  target="_blank"
                >
                  <p>California Privacy</p>
                </Link>

                <Link
                  className="footer-link"
                  to={{
                    pathname: "https://uphold.com/en/legal/cookie-policy ",
                  }}
                  target="_blank"
                >
                  <p>Cookie Policy</p>
                </Link>

                <Link
                  className="footer-link"
                  to={{
                    pathname:
                      "https://uphold.com/en/legal/important-disclosures ",
                  }}
                  target="_blank"
                >
                  <p>Important Disclosures</p>
                </Link>

                <Link
                  className="footer-link"
                  to={{
                    pathname:
                      "https://uphold.com/en/legal/licenses-for-residents-of-united-states ",
                  }}
                  target="_blank"
                >
                  <p>US Licensing</p>
                </Link>
              </div>
            </Col> */}
          </Row>
        </div>
        <div className="footer-bootom text-center" style={{ paddingTop: 50 }}>
          <p>
            MetrocommOnline Europe Limited, Reg No. 09281410, Registered Office:
            Suite A, 6 Honduras Street, London, England, EC1Y 0TH
          </p>
          <p>
            MetrocommOnline HQ Inc. NMLS ID No. 1269875 © MetrocommOnline 2021.
            All Rights Reserved. * Service may be provided by third parties and
            be subject to additional terms
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
