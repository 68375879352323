import React, { Component } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Index from "./Index/Index";
import About from "./About/About";
import Save from "./Save/Save";

class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={Index} />
            <Route exact path="/about" component={About} />
            <Route path="/save" component={Save} />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
