import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import WOW from "wowjs";

import NavBar from "../components/NavBar/NavBar";
import Footer from "../components/Footer/Footer";

import saveLogo from "./images/saving.svg";

import "./Save.css";

class Save extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bgClass: "hero-index1",
      currentNo: 1,
    };
  }
  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
    this.interval = setInterval(() => {
      if (this.state.currentNo === 1) {
        this.setState({ bgClass: "hero-index2", currentNo: 2 });
      } else if (this.state.currentNo === 2) {
        this.setState({ bgClass: "hero-index3", currentNo: 3 });
      } else if (this.state.currentNo === 3) {
        this.setState({ bgClass: "hero-index4", currentNo: 4 });
      } else {
        this.setState({ bgClass: "hero-index1", currentNo: 1 });
      }
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }
  render() {
    var className = "hero-save "; // + this.state.bgClass;
    return (
      <div>
        <NavBar />
        <div className={className}>
          <div className="container">
            <Row>
              <Col sm>
                <div className="header-text">
                  <h3 className="wow fadeInUp">
                    "Get on the right
                    <br />
                    side of saving!"
                  </h3>
                  <p className="wow fadeInUp">
                    {/* With impressive interest rates, tools & guides,
                    UpholdBanking is the smartest way to plan, send money, save
                    & invest.
                    <br />
                    <br /> */}
                    With UpholdBanking Saving can be much more than storing
                    money. Automate your savings and earn awesome returns. No
                    penalties. No fees. Absolutely free.
                  </p>
                </div>
              </Col>
              <Col sm>
                <img className="wow fadeInUp" src={saveLogo} alt="save logo" />
              </Col>
            </Row>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Save;
